import React from 'react';
import Draggable from 'react-draggable';
import '98.css';
import face from '../images/face.jpg';

class MainWindow extends React.Component {
  render() {
    return (
      <Draggable
        handle=".title-bar"
        defaultPosition={{x: 0, y: 0}}
        onMouseDown={() => this.props.setWindowOnTop('main')}
        position={null}
        scale={1}
        >
        <div className="window main-window" style={{zIndex: this.props.zIndex}}>
          <div className={"title-bar" + (this.props.active ? "" : " inactive")}>
            <div className="title-bar-text">Brendan Wood</div>
          </div>
          <div className="window-body">
            <section className="field-row row-align-start">
                <ul className="tree-view img-frame-parent">
                    <li className="img-frame-child">
                        <img src={face} alt="My head"></img>
                    </li>
                </ul>
                <div>
                    <p className="main-window-text">Hi! I'm Brendan Wood, a software developer from Brisbane, Queensland.</p>
                    <p className="main-window-text">You can see more about me or contact me using the buttons below.</p>
                </div>
            </section>
            <section className="field-row" style={{justifyContent: 'flex-end'}}>
                <button id="about-button" 
                  onClick={() => this.props.controlWindow('about', true)}>
                  About
                </button>
                <button id="contact-button"
                  onClick={() => this.props.controlWindow('contact', true)}>
                  Contact
                </button>
            </section>
          </div>
        </div>
        </Draggable>
      );
    }
  }

export default MainWindow;