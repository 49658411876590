import React from 'react';
import MainWindow from './windows/MainWindow.js';
import AboutWindow from './windows/AboutWindow.js';
import ContactWindow from './windows/ContactWindow.js';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAbout: false, 
      showContact: false, 
      windowStack: 1,
      aboutIndex: 0,
      contactIndex: 0,
      mainIndex: 0,
      lastActiveWindow: 'main',
    };
  }

  controlWindow = (window, show) => {
    if (window === "about")
      this.setState({showAbout: show})
    else if (window === "contact")
      this.setState({showContact: show})
    else
      console.log("Unknown window");
    this.setWindowOnTop(window);
  }

  setWindowOnTop = (window) => {
    this.setState({windowStack: this.state.windowStack + 1});
    if (window === "about")
      this.setState({aboutIndex: this.state.windowStack})
    else if (window === "contact")
      this.setState({contactIndex: this.state.windowStack})
    else if (window === "main")
      this.setState({mainIndex: this.state.windowStack})
    else
      console.log("Unknown window");
    this.setState({lastActiveWindow: window});
  }

  render() {
    return (
      <div className="desktop">
        <MainWindow
          controlWindow={this.controlWindow}
          zIndex={this.state.mainIndex}
          setWindowOnTop={this.setWindowOnTop}
          active={this.state.lastActiveWindow === 'main'} />
        <AboutWindow
          display={this.state.showAbout} 
          controlWindow={this.controlWindow}
          zIndex={this.state.aboutIndex}
          setWindowOnTop={this.setWindowOnTop}
          active={this.state.lastActiveWindow === 'about'} />
        <ContactWindow
          display={this.state.showContact} 
          controlWindow={this.controlWindow}
          zIndex={this.state.contactIndex}
          setWindowOnTop={this.setWindowOnTop} 
          active={this.state.lastActiveWindow === 'contact'} />
      </div>
    );
  }
}

export default App;
