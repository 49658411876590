import React from 'react';
import Draggable from 'react-draggable';
import '98.css';

class ContactWindow extends React.Component {
  render() {
    if (!this.props.display)
      return null;
    return (
      <Draggable
        handle=".title-bar"
        defaultPosition={{x: 0, y: 0}}
        onMouseDown={() => this.props.setWindowOnTop('contact')}
        position={null}
        scale={1}
        >
        <div className="window contact-window" style={{zIndex: this.props.zIndex}}>
          <div className={"title-bar" + (this.props.active ? "" : " inactive")}>
            <div className="title-bar-text">Contact</div>
            <div className="title-bar-controls">
              <button onClick={() => this.props.controlWindow('contact', false)} aria-label="Close" />
            </div>
          </div>
          <div className="window-body">
            <section className="field-row row-align-start">
              <div className="field-row-stacked contact-field-row">
                <label htmlFor="text-email">Email</label>
                <section className="field-row">
                  <input id="text-email" className="contact-field" type="text" disabled="disabled" value="brendan@bwood.dev" />
                  <button id="button-email" className="contact-button" onClick={() => window.open('mailto:brendan@bwood.dev')}>Go</button>
                </section>
              </div>
            </section>
            <section className="field-row row-align-start">
              <div className="field-row-stacked contact-field-row">
                <label htmlFor="text-linkedin">LinkedIn</label>
                <section className="field-row">
                  <input id="text-linkedin" className="contact-field" type="text" disabled="disabled" value="https://www.linkedin.com/in/brendan-wood-67156775/"/>
                  <button id="button-linkedin" className="contact-button" onClick={() => window.open('https://www.linkedin.com/in/brendan-wood-67156775/')}>Go</button>
                </section>
              </div>
            </section>
            <section className="field-row row-align-start">
              <div className="field-row-stacked contact-field-row">
                <label htmlFor="text-github">GitHub</label>
                <section className="field-row">
                  <input id="text-github" className="contact-field" type="text" disabled="disabled" value="https://github.com/bjwood"/>
                  <button id="button-github" className="contact-button" onClick={() => window.open('https://github.com/bjwood')}>Go</button>
                </section>
              </div>
            </section>
            <section className="field-row top-pad-row center-section">
              <button onClick={() => this.props.controlWindow('contact', false)} aria-label="OK">OK</button>
            </section>
          </div>
        </div>
        </Draggable>
    );
  }
}

export default ContactWindow;