import React from 'react';
import Draggable from 'react-draggable';
import '98.css';

class AboutWindow extends React.Component {
  render() {
    if (!this.props.display)
      return null;
    return (
      <Draggable
        handle=".title-bar"
        defaultPosition={{x: 0, y: 0}}
        onMouseDown={() => this.props.setWindowOnTop('about')}
        position={null}
        scale={1}
        >
        <div className="window about-window" style={{zIndex: this.props.zIndex}}>
          <div className={"title-bar" + (this.props.active ? "" : " inactive")}>
            <div className="title-bar-text">About</div>
            <div className="title-bar-controls">
              <button onClick={() => this.props.controlWindow('about', false)} aria-label="Close" />
            </div>
          </div>
          <div className="window-body">
            <p>I graduated with a Bachelor of IT in Software Design in 2015, and I've been developing software professionally ever since. I've mostly worked on .NET and Python projects to date, but I'm looking to expand my knowledge of React and Node, among other technologies.</p>
            <p>Not coincidentally, this website was built with React. It makes use of the wonderful <a href="https://jdan.github.io/98.css/" target="_blank" rel="noreferrer">98.css library</a>.</p>
            <section className="field-row top-pad-row center-section">
              <button onClick={() => this.props.controlWindow('about', false)} aria-label="OK">OK</button>
            </section>
          </div>
        </div>
        </Draggable>
    );
  }
}

export default AboutWindow;